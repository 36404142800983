body {
    margin: 8px;
}

#root h1 {
    margin: 20px 0;
    font-size: 2em;
    color: #000;
    font-family: 'Times New Roman', Times, serif
}
